import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;
window.jQuery = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import slick from 'slick-carousel';
import fullpage from 'fullpage.js';
import Masonry from 'masonry-layout';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import InfiniteScroll from 'infinite-scroll';
import matchesSelector from 'desandro-matches-selector';
import moment from 'moment';
import Pikaday from 'pikaday';

require('./cookies/cookies-banner.js');

$(document).foundation();

//****************************
// Veranstaltungen Form Ajax
//****************************

function funcVeranstaltungenDataForm()	{

 $('.form-veranstaltungen').submit(function(event) {
  event.preventDefault(); // Prevent the form from submitting via the browser
  var form = $(this);
 	var formById = '#'+form.attr('id');
 	var loader = '#'+form.attr('id') + ' .ajax-loader';
 	$(loader).removeClass('hide');

   $.ajax({
    type: form.attr('method'),
    url: form.attr('action'),
    data: form.serialize()
    }).done(function(data) {
    // Optionally alert the user of success here...
    $(formById).html(data);
				$(loader).addClass('hide');
    }).fail(function(data) {
    // Optionally alert the user of an error here...
    $(formById).html('<p>Invalid Data – Loading Ajax Form failed</p>');
    $(loader).addClass('hide');
   });

 });

};

//init
funcVeranstaltungenDataForm();

//****************************
// Date Picker
//****************************

if (document.getElementById('datePicker1')) {
    var picker1 = new Pikaday({
        field: document.getElementById('datePicker1'),
        i18n: {
            previousMonth: 'vorheriger Monat',
            nextMonth: 'nächster Monat',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        },
        format: 'DD.MM.YYYY',
        onSelect: function(date) {
            // const selectedDay = date.getDay();
            // const timeSelect = document.querySelector('#datum-zeit-11-22');
            // const existingOption = document.querySelector('#time-10-30');

            // if (selectedDay === 0) { // Sunday
            //     if (!existingOption) {
            //         const newOption = document.createElement('option');
            //         newOption.value = '10:30';
            //         newOption.text = '10:30';
            //         newOption.id = 'time-10-30';
            //         timeSelect.insertBefore(newOption, timeSelect.children[1]); // Insert in second place
            //     }
            // } else {
            //     if (existingOption) {
            //         existingOption.remove();
            //     }
            // }
        }
    });
}

if(document.getElementById('datePicker2')){
var picker2 = new Pikaday({
	field: document.getElementById('datePicker2'),
	i18n: {
		previousMonth : 'vorheriger Monat',
		nextMonth     : 'nächster Monat',
		months        : ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
		weekdays      : ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
		weekdaysShort : ['So','Mo','Di','Mi','Do','Fr','Sa']
	},
	format: 'DD.MM.YYYY',
	onSelect: function() {
		//console.log(this.getMoment().locale('de-ch').format('LL'));
	}
});
}

//****************************
// Reservation Form
//****************************

var step = 1;
var navProgress = document.getElementById("navProgress");
var resForm = document.getElementById("reservationForm");
var formStep2 = document.getElementById('formStep2');
var buttonStep2 = document.getElementById('buttonStep2');
var progressStep2 = document.getElementById('progressStep2');
var formStep3 = document.getElementById('formStep3');
var buttonStep3 = document.getElementById('buttonStep3');
var backStep3 = document.getElementById('backStep3');
var progressStep3 = document.getElementById('progressStep3');
var formStep4 = document.getElementById('formStep4');
var buttonStep4 = document.getElementById('buttonStep4');
var backStep4 = document.getElementById('backStep4');
var progressStep4 = document.getElementById('progressStep4');
var mainSelector = document.getElementById("mainSelector");
var paxSelector = document.getElementById("paxSelector");
var paxContainer = document.getElementById("paxContainer");


//on DOM loaded
document.addEventListener("DOMContentLoaded", function( event ) {
		autoPax();
});

//on change	Form
if ( resForm ) {
	resForm.addEventListener( 'change', function( event ) {
		getVals();
	});
}

//on change	mainSelector
if ( mainSelector) {
	mainSelector.addEventListener( 'change', function( event ) {
		autoPax();
	});
}

//on click	button step 2
if ( buttonStep2 ) {
	buttonStep2.addEventListener( 'click', function( event ) {
		validateStep2();
	});
}

//on click	progress step 2
if ( progressStep2 ) {
	progressStep2.addEventListener( 'click', function( event ) {
		gotoStep2();
	});
}

//on click	button step 3
if ( buttonStep3 ) {
	buttonStep3.addEventListener( 'click', function( event ) {
		validateStep3();
	});
}

//on click	back step 3
if ( backStep3 ) {
	backStep3.addEventListener( 'click', function( event ) {
		gotoStep2();
	});
}

//on click	progress step 3
if ( progressStep3 ) {
	progressStep3.addEventListener( 'click', function( event ) {
		validateStep2();
	});
}

//on click	button step 4
if ( buttonStep4 ) {
	buttonStep4.addEventListener( 'click', function( event ) {
		validateStep3();
	});
}

//on click	back step 4
if ( backStep4 ) {
	backStep4.addEventListener( 'click', function( event ) {
		gotoStep3();
	});
}

//on click	progress step 4
if ( progressStep4 ) {
	progressStep4.addEventListener( 'click', function( event ) {
		validateStep3();
	});
}

function gotoStep2() {
	navProgress.classList.add("step-2");
	navProgress.classList.remove("step-3");
	navProgress.classList.remove("step-4");
	formStep2.classList.remove("hide");
	buttonStep2.classList.remove("hide");
	progressStep2.classList.remove("is-checked");
	progressStep2.classList.add("is-active");
	formStep3.classList.add("hide");
	buttonStep3.classList.add("hide");
	backStep3.classList.add("hide");
	progressStep3.classList.remove("is-active");
	formStep4.classList.add("hide");
	buttonStep4.classList.add("hide");
	backStep4.classList.add("hide");
	progressStep4.classList.remove("is-active");
	scrollTop();
}

function gotoStep3() {
	navProgress.classList.remove("step-2");
	navProgress.classList.add("step-3");
	navProgress.classList.remove("step-4");
	formStep2.classList.add("hide");
	buttonStep2.classList.add("hide");
	progressStep2.classList.remove("is-active");
	formStep3.classList.remove("hide");
	buttonStep3.classList.remove("hide");
	backStep3.classList.remove("hide");
	progressStep3.classList.remove("is-checked");
	progressStep3.classList.add("is-active");
	formStep4.classList.add("hide");
	buttonStep4.classList.add("hide");
	backStep4.classList.add("hide");
	progressStep4.classList.remove("is-active");
	scrollTop();
}

function gotoStep4() {
	navProgress.classList.remove("step-2");
	navProgress.classList.remove("step-3");
	navProgress.classList.add("step-4");
	formStep2.classList.add("hide");
	buttonStep2.classList.add("hide");
	progressStep2.classList.remove("is-active");
	formStep3.classList.add("hide");
	buttonStep3.classList.add("hide");
	backStep3.classList.add("hide");
	progressStep3.classList.remove("is-active");
	formStep4.classList.remove("hide");
	backStep4.classList.remove("hide");
	buttonStep4.classList.remove("hide");
	progressStep4.classList.remove("is-checked");
	progressStep4.classList.add("is-active");
	scrollTop();
}


function validateStep2() {
	var elems = document.getElementById('reservationForm').elements;
	var elemArray = new Object();
	var validStep2 = true;
	for (var i = 0; i < elems.length; i++) {
		//console.log(elems[i]);
		if(elems[i].getAttribute('data-required') == 'step2' && !elems[i].value){
			elems[i].classList.add("error");
			validStep2 = false;
		}
	}
	//console.log(validStep2);
	if(validStep2 == true){
		progressStep2.classList.add("is-checked");
		step = 2;
		gotoStep3();
	} else {
		step = 1;
		scrollTop();
	}
}

function validateStep3() {
	validateStep2();
	var elems = document.getElementById('reservationForm').elements;
	var elemArray = new Object();
	var validStep3 = true;
	for (var i = 0; i < elems.length; i++) {
		//console.log(elems[i]);
		if(elems[i].getAttribute('data-required') == 'step3' && !elems[i].value){
			elems[i].classList.add("error");
			validStep3 = false;
		}
	}
	//console.log(validStep3);
	if(validStep3 == true && step == 2){
		progressStep3.classList.add("is-checked");
		step = 3;
		gotoStep4();
	} else {
		step = 1;
		scrollTop();
	}
}

// get the form element names and values
function getVals() {
	var elems = document.getElementById('reservationForm').elements;
	var elemArray = new Object();
	for (var i = 0; i < elems.length; i++) {
		//console.log(elems[i].type);
		elems[i].classList.remove("error");
		//writeArray
		if (elems[i].type == "text" && elems[i].value || elems[i].type == "textarea" && elems[i].value || elems[i].type == "radio" && elems[i].checked == true  || elems[i].type == "checkbox" && elems[i].checked == true || elems[i].type == "select-one" && elems[i].value ){
			elemArray[elems[i].id] = elems[i].value;
		}
	}
	checkVals(elemArray);
	return false;
}

// check values
function checkVals(elemArray, elems) {
	var str_result = '';
	var label = '';
	var elems_obj = {};

	for (var key in elemArray) {
		label = document.getElementById(key).getAttribute('data-label');
		elems_obj[label] = elemArray[key];
		str_result+= '<div class="grid-x grid-margin-x"><div class="legend medium-shrink cell">' + label + '</div><div class="value medium-auto cell">' + elemArray[key] + '</div></div>';
	}

// add values to hidden field as JSON object
	$('#input_hidden_field_obj').val(JSON.stringify(elems_obj));
	var value_obj = $('#input_hidden_field_obj').val();
	value_obj = JSON.parse(value_obj);

// add result to div as HTML
	document.getElementById('result').innerHTML = str_result;

	//console.log(value_obj);
	//console.log(str_result);
}

function scrollTop() {
 document.body.scrollTop = 0;
 document.documentElement.scrollTop = 0;
}

function autoPax() {
	var str_result = '';
	if ( mainSelector && paxSelector && paxContainer) {
		var max = mainSelector.options[mainSelector.selectedIndex].getAttribute('data-max');
		var selected = paxSelector.getAttribute('data-selected');
		max = (max*1) + 1;
		str_result+= '<option value="">Bitte wählen</option>';
		if('1 Person' == selected){ isSelected = ' selected'}
		str_result+= '<option value="1 Person"' + isSelected + '>1 Person</option>';
		for (var i = 2; i < max; i++) {
		var isSelected = '';
		if(i + ' Personen' == selected){ isSelected = ' selected'}
		str_result+= '<option value="' + i + ' Personen"' + isSelected + '>' + i + ' Personen</option>';
		}
		// add result to Select as HTML
		paxSelector.innerHTML = str_result;
		if (max > 1){
			paxContainer.classList.remove("hide");
		} else {
			paxContainer.classList.add("hide");
		}
		//console.log(str_result);
	}
}


//****************************
// Navigate
//****************************

$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
 if (newSize == 'large' || newSize == 'xlarge' || newSize == 'xxlarge'){
	if ($('#offCanvas')){
		$('#offCanvas').foundation('close');
	}
	$('#menuButtonMobile').removeClass('is-active');
 } else {
  $('#offCanvasContent').removeClass('is-active');
  $('#menuButtonScreen').removeClass('is-active');
 }
});
$('#navigationMenu a').click( function() {
	if ($('#offCanvas')){
		$('#offCanvas').foundation('close');
	}
	$('#offCanvasContent').removeClass('is-active');
});
$("#offCanvas").on("opened.zf.offcanvas", function(e) {
 $('#menuButtonMobile').addClass('is-active');
 $('#menuButtonMap').addClass('is-pos');
});
$("#offCanvas").on("closed.zf.offcanvas", function(e) {
 $('#menuButtonMobile').removeClass('is-active');
 $('#menuButtonMap').removeClass('is-pos');
});
$("#offCanvasContent").on("on.zf.toggler", function(e) {
 $('#menuButtonScreen').addClass('is-active');
 $('#menuButtonMap').addClass('is-pos');
});
$("#offCanvasContent").on("off.zf.toggler", function(e) {
 $('#menuButtonScreen').removeClass('is-active');
 $('#menuButtonMap').removeClass('is-pos');
});
$("#mapContent").on("on.zf.toggler", function(e) {
 $('#menuButtonMap').addClass('is-active');
 $('#menuButtonMap').removeClass('is-pos');
 if ($('#offCanvas')){
	 $('#offCanvas').foundation('close');
 }
 $('#offCanvasContent').removeClass('is-active');
 $('#menuButtonScreen').removeClass('is-active');
 $('#menuButtonMobile').removeClass('is-active');
});
$("#mapContent").on("off.zf.toggler", function(e) {
 $('#menuButtonMap').removeClass('is-active');
 $('#menuButtonMap').removeClass('is-pos');
});

// set up bouncing buttons
$('.click').click( function() {
 $(this).addClass('is-clicked');
  function removeClicked(){
   $('.click').removeClass('is-clicked');
  }
  setTimeout(removeClicked, 200);
});

// Teaser Cards No hover effect
const cards = document.querySelectorAll('.teaser-card');
for (const card of cards) {
	const button = card.querySelector('.back a')
	card.addEventListener('click', function() {
		button.click();
	})
}

// // only of not touch screen
// if (window.matchMedia("(hover: none)").matches) {
// 	const cards = document.querySelectorAll('.flip');
// 	for (const card of cards) {
// 		const button = card.querySelector('.back a')
// 		card.addEventListener('click', function() {
// 			button.click();
// 		})
// 	}
// } else {
// 	// set up flip panels
// 	$('.flip .front').mouseenter(function(e){
// 	  $(this).closest('.flip' ).addClass('is-flipped');
// 	  e.preventDefault();
// 	});
// 	$('.flip .back').mouseleave(function(e){
// 		$(this).closest('.flip' ).removeClass('is-flipped');
// 		//e.preventDefault();
// 	});
	
// 	const cards = document.querySelectorAll('.flip');
// 	for (const card of cards) {
// 		const button = card.querySelector('.back a')
// 		card.addEventListener('click', function() {
// 			if (card.classList.contains('is-flipped')) {
// 				button.click();
// 			}
// 		})
// 	}
// }




//****************************
// Fullpage
//****************************

new fullpage('#fullpage', {
	//options here
	licenseKey: '849FE97E-010B4EAC-A457707C-1CEABBF3',
	autoScrolling: false,
	scrollHorizontally: false,
	lazyLoading: true,
	slidesNavigation: false,
	controlArrows: true,
	menu: '#menu',
	scrollingSpeed: 1000,
	responsiveWidth: 1024,
	responsiveHeight: 700,
	scrollBar: true,
	fitToSection: false,
});

//methods
// fullpage_api.setAllowScrolling(false);


//*********************************
// slick-slider
//*********************************


//slick-slider-single-slide
$('.slick-slider-single-slide').slick({
  //adaptiveHeight: true,
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnFocus: false,
  pauseOnHover: false,
  pauseOnDotsHover: false
});

//slick-slider-single-fade
$('.slick-slider-single-fade').slick({
  //adaptiveHeight: true,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  pauseOnFocus: false,
  pauseOnHover: false,
  pauseOnDotsHover: false
});

//slick-slider-single-slide-autoplay
$('.slick-slider-single-slide-autoplay').slick({
  //adaptiveHeight: true,
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnFocus: false,
  pauseOnHover: false,
  pauseOnDotsHover: false
});

//slick-slider-single-fade-autoplay
$('.slick-slider-single-fade-autoplay').slick({
  //adaptiveHeight: true,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnFocus: false,
  pauseOnHover: false,
  pauseOnDotsHover: false
});

//****************************
// Masonry
//****************************

var elemMasonryIl = document.querySelector('.masonry-il');
if (elemMasonryIl) {
 var msnry = new Masonry( elemMasonryIl, {
  itemSelector: '.cell',
   percentPosition: true
 });
 imagesLoaded( elemMasonryIl ).on( 'progress', function() {
   msnry.layout();
 });
}

//****************************
// Isotope
//****************************

//assign infinite scroll to imagesloaded
InfiniteScroll.imagesLoaded = imagesLoaded;

var elemIsotopeIs = document.querySelector('.isotope-is');
if ( elemIsotopeIs) {
  var iso = new Isotope( elemIsotopeIs, {
  masonry: {
   columnWidth: '.cell',
   horizontalOrder: true
  },
  itemSelector: 'none', // select none at first
  columnWidth: '.cell',
  percentPosition: true,
  stagger: 30,
  // nicer reveal transition
  visibleStyle: { transform: 'translateY(0)', opacity: 1 },
  hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
 });

 // filter Array
 var filters = [];

  // bind filter button click
 var filtersElem = document.querySelector('.filters-button-group');
 if ( filtersElem ) {

 filtersElem.addEventListener( 'click', function( event ) {

  var buttonvalues = filtersElem.getElementsByClassName('button');
  if(event.target.className.indexOf('button-all') !== -1){
   for (i=1; i<buttonvalues.length; i++) {
    filters = [];
    buttonvalues[i].classList.remove('is-checked');
    buttonvalues[0].classList.add('is-checked');
   }
  }
  else {

   var isChecked = event.target.classList.toggle('is-checked');
   var filterValue = event.target.getAttribute('data-filter');
   buttonvalues[0].classList.remove('is-checked');

   if ( isChecked ) {
    addFilter( filterValue );
   } else {
    removeFilter( filterValue );
   }
  }

  // add checked top button-all when no filters selected
	 if(filters == ''){
		 buttonvalues[0].classList.add('is-checked');
	 }

  // add Filters to Filter Array
  function addFilter( filterValue ) {
    filters.push( filterValue );
    //console.log('added');
  }

  // remove Filters from Filter Array
  function removeFilter( filterValue ) {
    var index = filters.indexOf( filterValue);
     filters.splice( index, 1 );
     //console.log('removed');
  }

  // only work with buttons
  if ( !matchesSelector( event.target, 'button' ) ) {
   return;
  }
  var filterValue = event.target.getAttribute('data-filter');
   iso.arrange({ filter:  filters.join(',') });
  });

 }

 // initial items reveal
 imagesLoaded( elemIsotopeIs, function() {
  elemIsotopeIs.classList.remove('are-images-unloaded');
  iso.options.itemSelector = '.cell';
  var items =  elemIsotopeIs.querySelectorAll('.cell');
  iso.appended( items );
 });
 // load pages
 var links = document.querySelectorAll('.nav-is a');
 var nextPageSlugs = [];
 for(var i = 0; i < links.length; i++){
   nextPageSlugs.push(links[i].href);
 }
 function getPagePath() {
   var slug = nextPageSlugs[ this.loadCount ];
  if (typeof(slug) !== 'undefined') {
    return slug;
   }
 }
 // init Infinite Scroll
 var infScroll = new InfiniteScroll( elemIsotopeIs, {
  path: getPagePath,
  append: '.cell',
  outlayer: iso,
  status: '.page-load-status',
  history: false,
  //using button, disable loading on scroll
  //button: '.view-more-button',
  //scrollThreshold: false,
 });
 // Callbacks
 infScroll.on( 'append', function( response, path, items ) {
  //console.log( 'append ' + path );
 });
 infScroll.on( 'load', function( response, path ) {
  //console.log( 'load ' + path  );
 });
}

//*******************************
// Smooth Scrolling (jQuery)
//*******************************

//https://css-tricks.com/snippets/jquery/smooth-scrolling/

 // Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
.not('a.afterglow[href="#"]')
.not('[href="#"]')
.not('[href="#0"]')
.click(function(event) {
  // On-page links
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
    &&
    location.hostname == this.hostname
  ) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000, function() {
        // Callback after animation
        // Must change focus!
        var $target = $(target);
        $target.focus();
        if ($target.is(":focus")) { // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        };
      });
    }
  }
});


//*******************************
// HOTEL SPIDER WIDGET
//*******************************

// Startseite
if (document.getElementById('spiderBooking')) {
	let script = document.createElement('script');

	script.src = "https://wbe-static.hotel-spider.com/widget/spiderBooking4.js";

	script.onload = function () {
		$('#spiderBooking').SpiderBooking4({
			//hotelId: '00W57173342f0f60'
			hotelId: '02S6135be1975694',
			lang: 'de',
			dateFormat: 'D, d.m.'
		});
	};

	document.head.appendChild(script);

	/* $(function() {
		$('#spiderBooking').SpiderBooking4({
			hotelId: '02S6135be1975694'
		});
	}); */
}

// Zimmer-Detailseite
if (document.getElementById('spiderBookingRoom')) {
	let script = document.createElement('script');

	script.src = "https://wbe-static.hotel-spider.com/widget/spiderBooking4.js";

	script.onload = function () {
		$('#spiderBookingRoom').SpiderBooking4({
			//hotelId: '00W57173342f0f60'
			hotelId: '02S6135be1975694',
			template: 'vertical',
			lang: 'de',
			dateFormat: 'D, d.m.'
		});
	};

	document.head.appendChild(script);

	/* $(function() {
		$('#spiderBooking').SpiderBooking4({
			hotelId: '02S6135be1975694'
		});
	}); */
}


//**************************************************************************************** New Things

//---------------------------------------------------- startpage hero

const planButton = document.querySelector('.startpage-hero__link.--plan');
const planCont = document.querySelector('.startpage-hero__map');
const planCloseBtn = document.querySelector('.startpage-hero__map__close-icon');

if (planButton){
	planButton.addEventListener('click', function(e) {
		planCont.classList.add('--open');
	});
}

if (planCloseBtn){
	planCloseBtn.addEventListener('click', function(e) {
		planCont.classList.remove('--open');
	});
}
//---------------------------------------------------- startpage navigation

const menuBtn = document.querySelector('.main-nav__mobile-button');
const menuCloseBtn = document.querySelector('.main-nav__mobile-close-button');
const mobileMenu = document.querySelector('.main-menu-wrapper');

if (menuBtn){
	menuBtn.addEventListener('click', function(e) {
		mobileMenu.classList.add('__mobile--open');
	});
}

if (menuCloseBtn){
	menuCloseBtn.addEventListener('click', function(e) {
		mobileMenu.classList.remove('__mobile--open');
	});
}





//---------------------------------------------------- brunch reservation
document.addEventListener('DOMContentLoaded', function() {
	const form = document.querySelector('#reservationForm');
	if (!form) return;

	const inputs = document.querySelectorAll('input');
	const select = document.querySelector('#zeit-einfach-11-22');
	if (!select) return;

	const originalOptions = Array.from(select.options).map(option => ({
		value: option.value,
		text: option.textContent
	}));

	for (const input of inputs) {
		if (input.id === 'Auswahl Veranstaltung-3') {
			input.addEventListener('change', function() {
				select.innerHTML = '<option value="">Bitte wählen</option>';
				
				if (input.checked) {
					const newOptions = [
						'10:30', '11:00', '11:30', '12:00', '12:30',
						'13:00', '13:30', '14:00'
					];

					newOptions.forEach(time => {
						if (![...select.options].some(option => option.value === time)) {
							const option = document.createElement('option');
							option.value = time;
							option.textContent = time;
							select.appendChild(option);
						}
					});
				} else {
					originalOptions.forEach(optionData => {
						if (![...select.options].some(option => option.value === optionData.value)) {
							const option = document.createElement('option');
							option.value = optionData.value;
							option.textContent = optionData.text;
							select.appendChild(option);
						}
					});
				}
			});
		}
	}
});

//---------------------------------------------------- tanzhüsli reservation
document.addEventListener('DOMContentLoaded', function() {
	const form = document.querySelector('#reservationForm');
	if (!form) return;

	const select = document.querySelector('#mainSelector');
	if (!select) return;

	if (select.value === 'Tanzhüsli') {
		const timeSelect = document.querySelector('#zeit-doppelt-abend1');
		const options = timeSelect.querySelectorAll('option');

		options.forEach(option => {
			const optionValue = option.value;
			if (optionValue >= '8:00' && optionValue <= '9:45') {
				option.style.display = 'none';
			}
		});
	}

	select.addEventListener('change', function(e) {
		const value = e.target.value;
		const timeSelect = document.querySelector('#zeit-doppelt-abend1');
		const options = timeSelect.querySelectorAll('option');
	
		if (value === 'Tanzhüsli') {
			options.forEach(option => {
				const optionValue = option.value;
				if (optionValue >= '8:00' && optionValue <= '9:45') {
					option.style.display = 'none';
				} else {
					option.style.display = '';
				}
			});
		} else {
			options.forEach(option => {
				option.style.display = '';
			});
		}
	});
});